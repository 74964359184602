<template>
    <div class="home_conetnt">
        <el-row type="flex" justify="center" >
            <el-col :xs="24" :sm="24" :md="24" :lg="20" :xl="20">
                <div class="header">
                    <div class="logo">
                        <img src="@/assets/logo.png" alt="" srcset="">
                    </div>
                    <a href="http://gxy.x-curve.ltd" target="__blank" class="login_btn">
                        登录
                    </a>
                </div>
            </el-col>
        </el-row>
        <div class="banner_bg">
            <div class="banner">
            <el-row type="flex" justify="center" >
            <!-- <img class="banner_bg" src="@/assets/banner.png" alt=""> -->
            <el-col :xs="24" :sm="24" :md="24" :lg="20" :xl="20">
                <div class="banner_main">
                    <div class="banner_main_conetnt">
                        <div data-wow-delay=".1s" class="wow animated fadeInDown banner_main_conetnt_title">
                            快速打造属于您的灵活用工平台业务起步无门槛
                        </div>
                        <div class="animated fadeInLeft banner_main_conetnt_des">
                            <span class="hidden-md-and-up">
                                通过工禧云的高效平台技术，简单的对接即可生成属于您的平台。 <br>可使用您的品牌，快速开展业务。
                            </span>
                            <span class="hidden-md-and-down">
                                <p>
                                通过工禧云的高效平台技术，简单的对接即可生成属于您的平台。 
                                </p>
                                <p>
                                    可使用您的品牌，快速开展业务。
                                </p>
                            </span>
                        </div>
                        <a @click="open_form" href="javascript:;" class="wow animated fadeInUp banner_main_conetnt_btn">
                            开启业务
                        </a>
                    </div>
                
                        <div data-wow-delay=".5s" class="wow animated fadeIn">
                        <img class="banner_main_img" src="@/assets/banner_img.png" alt="" srcset="">
                    </div>
                </div>
            </el-col>
            </el-row>
        </div>
        </div>
        

        <el-row type="flex" justify="center">
            <el-col :xs="24" :sm="24" :md="24" :lg="20" :xl="20">
                <div class="main">
                    <el-row>
                        <div class="doubt">
                        <el-col :xs="24" :sm="24" :md="24" :lg="13" :xl="13">
                            <div class="doubt_left wow animated fadeInLeft">
                                <div class="doubt_left_title_div">
                                    <div class="doubt_left_title">什么是灵活用工？</div>
                                    <div class="doubt_left_img hidden-md-and-down">
                                        <img src="@/assets/left_icon.png" alt="" srcset="">
                                    </div>
                                </div>
                                <div class="doubt_left_main">
                                    灵活用工是指在企业外部用工时，企业与个人双方建立平等的业务承揽合作关系，而非传统的劳务关系或劳动关系。举例:美团骑手、滴滴司机。
                                </div>
                            </div>
                        </el-col>
                         <el-col :xs="24" :sm="24" :md="24" :lg="11" :xl="11">
                            <div class="doubt_right wow animated fadeInRight">
                                <div class="doubt_right_ul">
                                    <div class="doubt_right_li">
                                        <img src="@/assets/shui.png" alt="" srcset="">
                                        <p>降低个税用工成本</p>
                                    </div>
                                    <div class="doubt_right_li">
                                        <img src="@/assets/fapiao.png" alt="" srcset="">
                                        <p>专用增值税发票</p>
                                    </div>
                                    <div class="doubt_right_li">
                                        <img src="@/assets/shuishou.png" alt="" srcset="">
                                        <p>各地园区政策优惠</p>
                                    </div>
                                    <div class="doubt_right_li">
                                        <img src="@/assets/caozuo.png" alt="" srcset="">
                                        <p>操作简单方便</p>
                                    </div>
                                </div>
                            </div>
                        </el-col>
                    </div>
                </el-row>

                <div data-wow-delay=".5s" class="main_task wow animated fadeInDown">
                     <el-image 
                        :src="main_task_url" 
                        :preview-src-list="main_task_list">
                    </el-image>
                </div>

                <div data-wow-delay=".5s" class="main_policy wow animated fadeIn">
                    <div class="common_title">
                        <div class="common_title_left">
                            <div class="common_title_title">
                                优惠政策
                            </div>
                            <img class="hidden-md-and-down" src="@/assets/left_icon.png" alt="" srcset="">
                        </div>
                        <div class="common_title_right hidden-md-and-down">
                            国务院大力落实就业优先政策，促进零工市场、灵活就业健康发展。
                        </div>
                    </div> 

                    <div class="mySwiper">
                        <swiper ref="mySwiper" :options="swiperOptions">
                            <swiper-slide v-for="item in newsList" :key="item.id">
                                <div class="main_policy_news_list">
                                    <div class="main_policy_news_list_title">
                                        <div>
                                            {{item.title}} <span>{{item.tag}}</span>
                                        </div>
                                        <div class="main_policy_news_list_time  hidden-md-and-up">{{item.time}}</div>
                                    </div>
                                    <div class="main_policy_news_list_des">
                                        <div>{{item.des}}</div>
                                        <div class="main_policy_news_list_time  hidden-md-and-down">发布时间：{{item.time}}</div>
                                    </div>
                                </div>
                            </swiper-slide>
                            <!-- <div class="swiper-pagination" slot="pagination"></div> -->
                        </swiper>
                    </div>
                </div>
            </div>
            </el-col>
        </el-row>

        <el-row type="flex" justify="center" >
            <el-col :xs="24" :sm="24" :md="24" :lg="20" :xl="20">
                <div class="main_contrast">
                    <div class="main_contrast_con">
                        <div class="common_title">
                            <div class="common_title_left">
                                <div class="common_title_title">
                                    收入对比
                                </div>
                                <img class="hidden-md-and-down" src="@/assets/left_icon.png" alt="" srcset="">
                            </div>
                            <div class="common_title_right hidden-md-and-down">
                                假定兼职薪资每月<span>20000</span>元，使用灵活用工策划结果对比
                            </div>
                        </div> 
                        <div class="main_contrast_des hidden-md-and-up">假定兼职薪资每月<span>20000</span>元，使用灵活用工策划结果对比。</div>
                        <div data-wow-delay=".5s" class="main_contrast_img wow animated fadeInDown">
                            <el-image 
                                :src="contrast_url" 
                                :preview-src-list="contrast_list">
                            </el-image>
                        </div>
                    </div>
                </div>
            </el-col>
        </el-row>


        <el-row type="flex" justify="center" >
            <el-col :xs="24" :sm="24" :md="24" :lg="20" :xl="20">
                <div class="main">
                    <div class="cooperation">
                        <div class="cooperation_title">
                            工禧云，灵活用工产业合作平台
                        </div>
                        <div class="cooperation_des">
                            优选各地合规的税源地服务商，严格执行服务商的准入筛选，保障政策优惠稳定。
                        </div>
                        <div data-wow-delay=".5s" class="cooperation_img wow animated fadeInDown">
                            <el-image 
                                :src="cooperation_url" 
                                :preview-src-list="cooperation_list">
                            </el-image>
                        </div>
                    </div>
                    
                    <div class="advantage">
                        <div data-wow-delay=".5s" class="advantage_text wow animated fadeInLeft">
                            <div class="advantage_text_title">高效支付结算，保障资金安全</div>
                            <div class="advantage_text_img hidden-md-and-down">
                                <img src="@/assets/left_icon.png" alt="" srcset="">
                            </div>
                            <div class="advantage_text_des hidden-md-and-down">直连银行接口，第三方监管，资金线上高效结算<br />资金极速到账，保障安全。</div>
                            <div class="advantage_text_des hidden-md-and-up">直连银行接口，第三方监管，资金线上高效结算资金极速到账，保障安全。</div>
                        </div>
                        <div data-wow-delay=".5s" class="advantage_img wow animated fadeInUp">
                            <img src="@/assets/anquan.png" alt="" srcset="">
                        </div>
                    </div>

                    <div class="advantage">
                        <div data-wow-delay=".5s" class="advantage_img2 hidden-md-and-down wow animated fadeInLeft">
                            <img src="@/assets/guanli.png" alt="" srcset="">
                        </div>
                        <div data-wow-delay=".5s" class="advantage_text wow animated fadeInDown">
                            <div class="advantage_text_title">高效的SaaS化管理工具平台</div>
                            <div class="advantage_text_img hidden-md-and-down">
                                <img src="@/assets/left_icon.png" alt="" srcset="">
                            </div>
                            <div class="advantage_text_title_label">业务流程线上化</div>
                            <div class="advantage_text_des">包括人员管理、任务派发、薪资核算、发票开具等，提供更高效的企业服务。</div>
                            <div class="advantage_text_title_label">全流程的实名认证体系</div>
                            <div class="advantage_text_des">专业的电子合同签约系统，高效安全的结算平台，确保每一笔交易安全可控。</div>
                        </div>
                        <div data-wow-delay=".5s" class="advantage_img2 hidden-md-and-up wow animated fadeInLeft">
                            <img src="@/assets/guanli.png" alt="" srcset="">
                        </div>
                    </div>

                </div>
            </el-col>
        </el-row>

        <div class="sdk">
            <el-row type="flex" justify="center" >
            <el-col :xs="24" :sm="24" :md="24" :lg="20" :xl="20">
            <div class="sdk_main">
                <div class="common_title2">
                    <div class="common_title2_head">
                        <div class="hidden-md-and-down"><img src="@/assets/right_icon.png" alt="" srcset=""></div>
                        <div class="common_title2_head_title">免费API/SDK对接</div>
                        <div class="hidden-md-and-down"><img src="@/assets/left_icon.png" alt="" srcset=""></div>
                    </div>
                    <div class="common_title2_des">
                        与企业互连，实现数据交互，技术团队免费定制个性化接口，更可驻场协作，一解企业技术之忧。
                    </div>
                </div>
                <el-image 
                    data-wow-delay=".5s" 
                    class="sdk_img wow animated fadeIn"
                    :src="sdk_img_url" 
                    :preview-src-list="sdk_img_list">
                </el-image>
            </div>
            </el-col>
            </el-row>
        </div>
        <el-row type="flex" justify="center" >
            <el-col :xs="24" :sm="24" :md="24" :lg="20" :xl="20">
                <div class="main">
                    <div data-wow-delay=".5s" class="risk wow animated fadeInUp">
                        <div class="common_title2">
                            <div class="common_title2_head">
                                <div class="hidden-md-and-down"><img src="@/assets/right_icon.png" alt="" srcset=""></div>
                                <div class="common_title2_head_title">风控护航 安心无忧</div>
                                <div class="hidden-md-and-down"><img src="@/assets/left_icon.png" alt="" srcset=""></div>
                            </div>
                            <div class="common_title2_des">
                                银企互联，结算透明，支付安全，自建风控体系，全面覆盖前中后期；大数据加密算法技术，保障用户信息安全。
                            </div>
                        </div>
                        <div class="risk_uls">
                            <div class="risk_li">
                                <div class="risk_li_head">
                                    <div class="risk_li_title">事前<span>尽调</span></div>
                                    <div class="risk_li_num">01</div>
                                </div>
                                <div class="risk_li_border"></div>
                                <div class="risk_des">
                                    · 禁入企业及人群筛查 <br />· 企业业务及用工情况尽调 <br />· 身份真实性认证
                                </div>
                                <div class="risk_href">
                                    <img src="@/assets/href.png" alt="" srcset="">
                                </div>
                            </div>
                            <div class="risk_li">
                                <div class="risk_li_head">
                                    <div class="risk_li_title">事中<span>实时监控</span></div>
                                    <div class="risk_li_num">02</div>
                                </div>
                                <div class="risk_li_border"></div>
                                <div class="risk_des">
                                    · 分级定制风控环节<br />· 智能风控预警 <br />· 人机协调风控审核
                                </div>
                                <div class="risk_href">
                                    <img src="@/assets/href.png" alt="" srcset="">
                                </div>
                            </div>
                            <div class="risk_li">
                                <div class="risk_li_head">
                                    <div class="risk_li_title">事后<span>复核排查</span></div>
                                    <div class="risk_li_num">03</div>
                                </div>
                                <div class="risk_li_border"></div>
                                <div class="risk_des">
                                    · 复核排查异常<br />· 优化风控规则
                                </div>
                                <div class="risk_href">
                                    <img src="@/assets/href.png" alt="" srcset="">
                                </div>
                            </div>
                        </div>
                    </div>

                    <div data-wow-delay=".5s" class="compliance wow animated fadeInUp">
                        <div class="common_title2">
                            <div class="common_title2_head">
                                <div class="hidden-md-and-down"><img src="@/assets/right_icon.png" alt="" srcset=""></div>
                                <div class="common_title2_head_title">交易鉴证 合法合规</div>
                                <div class="hidden-md-and-down"><img src="@/assets/left_icon.png" alt="" srcset=""></div>
                            </div>
                            <div class="common_title2_des">
                                严格控制合同流、业务流、资金流，票据流四流合一，为企业提供合规性交付文件。
                            </div>
                        </div>

                        <div class="compliance_ul hidden-md-and-down">
                            <div class="compliance_li">
                                <div class="compliance_li_title">企业端</div>
                                <div class="compliance_li_border"></div>
                                <div class="compliance_li_des">开具增值税专票/增值税普票</div>
                                <img class="compliance_li_img1" src="@/assets/compliance1.png" alt="" srcset="">
                            </div>
                            <div class="compliance_li">
                                <div class="compliance_li_title">自由职业者</div>
                                <div class="compliance_li_border"></div>
                                <div class="compliance_li_des">个税缴纳清单：以个人身份证号码为标志，申报个税等凭证，（加盖代征明细税收业务专用章）</div>
                                <img class="compliance_li_img2" src="@/assets/compliance2.png" alt="" srcset="">
                            </div>
                            <div class="compliance_li">
                                <div class="compliance_li_title">税源地自留</div>
                                <div class="compliance_li_border"></div>
                                <div class="compliance_li_des">代征单位完税证明：税源地作为税务部门授权的代征方，将所代征税款上缴国库并完税的凭证</div>
                                <img class="compliance_li_img3" src="@/assets/compliance3.png" alt="" srcset="">
                            </div>
                        </div>
                        <div class="compliance_ul hidden-md-and-up">
                            <div class="compliance_li">
                                <img class="compliance_li_img1" src="@/assets/compliance1.png" alt="" srcset="">
                               <div>
                                    <div class="compliance_li_title">企业端</div>
                                    <div class="compliance_li_des">开具增值税专票/增值税普票。</div>
                               </div>
                            </div>
                            <div class="compliance_li">
                                <img class="compliance_li_img2" src="@/assets/compliance2.png" alt="" srcset="">
                                <div>
                                    <div class="compliance_li_title">自由职业者</div>
                                    <div class="compliance_li_des">个税缴纳清单：以个人身份证号码为标志，申报个税等凭证，（加盖代征明细税收业务专用章）。</div>
                                </div>
                            </div>
                            <div class="compliance_li">
                                <img class="compliance_li_img3" src="@/assets/compliance3.png" alt="" srcset="">
                                <div>
                                    <div class="compliance_li_title">税源地自留</div>
                                    <div class="compliance_li_des">代征单位完税证明：税源地作为税务部门授权的代征方，将所代征税款上缴国库并完税的凭证。</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </el-col>
        </el-row>

        <div class="footer">
            <div class="footer_div"><a href="https://beian.miit.gov.cn/" target="__blank"> Copyright © 2021   沪ICP备2021013777号-2</a></div>
            <div>工禧云提供技术支持</div>
        </div>
        <el-collapse-transition>
        <div class="forms" v-show="show">
            <div class="forms_ul">
                <div class="forms_li">
                    <span>01</span> 联系我们
                </div>
                <div class="forms_li">
                    <span>02</span> 1对1了解您的情况
                </div>
                <div class="forms_li">
                    <span>03</span> 定制您的页面链接
                </div>
                <div class="forms_li">
                    <span>04</span> 开启您的灵活平台
                </div>
            </div>
            <div class="forms_form">
                <input type="text" v-model="mobile" placeholder="请输入您的手机号码，与我们联系">
                <div @click="sub" class="forms_btn">提交</div>
            </div>
                <img @click="close" class="close" src="@/assets/close.png" alt="">
        </div>
        </el-collapse-transition>
         <el-backtop :bottom="200">
             <div style="width: 48px; height: 48px">
            <img src="@/assets/top.png" width="48px" alt="" srcset="">
             </div>
        </el-backtop>
    </div>
</template>
<script>
import {WOW} from 'wowjs' 
import {Message} from 'element-ui'
import axios from 'axios'
import tasks from '@/assets/tasks.png'
import contrast from '@/assets/contrast.png'
import cooperation from '@/assets/cooperation.png'
import sdk_img from '@/assets/sdk_img.png'
export default {
    data(){
        return {
            mobile: '',
            newsList:[
                {
                    id: 1,
                    title: '二级相关政策推动',
                    tag: '人口政策',
                    des: '“十三五”期间延迟退休方案推出：从2018年开始，女性退休年龄每3年延迟一岁，男性退休年龄每6年延迟1岁，直到2045年同时达到65岁。',
                    time: '2018年'
                },
                {
                    id: 2,
                    title: '一级相关政策推动',
                    tag: '就业政策',
                    des: '2019年7月31日，国务院常务会议部署加大力度落实就业优先政策，要求完善新就业形态支持政策，促进零工市场、灵活就业等健康发展，培育就业新增长点。',
                    time: '2019年7月13日'
                },
                {
                    id: 3,
                    title: '二级相关政策推动',
                    tag: '税收政策',
                    des: '2019年1月1日起，社保入税政策正式实施，企业用人成本再次升高。',
                    time: '2019年1月1日'
                },
                {
                    id: 4,
                    title: '一级相关政策推动',
                    tag: '就业政策',
                    des: '2020年3月20日，李克强在统筹推进疫情防控和稳就业工作电视电话会议中指出：要千方百计加快恢复和稳定就业，为就业创业、灵活就业提供更多机会。',
                    time: '2020年3月20日'
                },
                {
                    id: 5,
                    title: '一级相关政策推动',
                    tag: '就业政策',
                    des: '2020年7月21日，国务院发布国办发〔2020〕24号文件，促进人才流动和灵活就业。',
                    time: '2020年7月21日'
                }
            ],
            show: true,
            main_task_url: tasks,
            main_task_list: [tasks],
            contrast_url: contrast,
            contrast_list: [contrast],
            cooperation_url: cooperation,
            cooperation_list:[cooperation],
            sdk_img_url: sdk_img,
            sdk_img_list:[sdk_img],
            swiperOptions: {
                loop: true,
                autoplay: true,
                delay: 2000,
                pagination: {
                    el: '.swiper-pagination'
                },
            }
        }
    },
    computed: {
        swiper() {
            return this.$refs.mySwiper.swiper
        }
    },
    methods:{
        open_form(){
            this.show = true
        },
        close(){
            this.show = false
        },
        sub(){
            console.log(process.env.VUE_APP_API_BASE_URL)
            if(this.mobile === '' || this.mobile === null || this.mobile === undefined){
                Message.error('请输入手机号后提交！')
                return false
            }
            axios.post(`${process.env.VUE_APP_API_BASE_URL}/api/submit/form`, {
                mobile: this.mobile
            }).then(res=>{
                if(res.data.code === 0){
                    Message.success('您已提交成功！')
                }else{
                    Message.warning(res.data.message)
                }
            }).catch(error=>{
                console.log(error)
                Message.warning('您提交的太快了！')
            })
        }
    },
    mounted() {
        this.$nextTick(() => {
      // 在dom渲染完后,再执行动画
            const wow = new WOW({
                live: false
            })
            wow.init()
        })
        // this.swiper.slideTo(3, 1000, false)
    }
}
</script>
<style lang="less">
@import "./home.less";
@import "./animate.less";
</style>
